// very ugly.

import wasmUrl from 'z3-solver/build/z3-built.wasm?url'
import workerUrl from 'z3-solver/build/z3-built.worker.js?url'
import z3BuiltUrl from 'z3-solver/build/z3-built.js?url'

import { init as initZ3Solver } from 'z3-solver';

declare global {
    var initZ3: (module: any) => Promise<any>;
}

export async function init() {
    const z3BuiltJs = await (await fetch(z3BuiltUrl)).text() + '\nexport {initZ3}';
    const blob = new Blob([z3BuiltJs], { type: "text/javascript" });
    const url = URL.createObjectURL(blob);
    const { initZ3 } = await import(/* @vite-ignore */url);
    URL.revokeObjectURL(url);
    const z3 = await initZ3({
        locateFile(filename: string) {
            if (filename.includes('wasm')) return wasmUrl;
            else return workerUrl;
        },
        mainScriptUrlOrBlob: z3BuiltUrl
    });

    // for whatever reason, it reads it from global??
    (globalThis as any).global = globalThis;
    global.initZ3 = () => z3;

    const z3lib = await initZ3Solver();

    delete (globalThis as any).initZ3;

    return z3lib;
}

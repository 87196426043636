import { LabData, getData, getIconUrl, types as factorioLabTypes, LabRecipe, LabItem, getRecipeItems } from './factoriolab-data.js';
import { Body1, Checkbox, Combobox, FluentProvider, Input, Label, Link, Option, Spinner, Subtitle2, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow, Title1, Title3, makeStyles, shorthands, typographyStyles, webLightTheme } from '@fluentui/react-components';
import { Button } from '@fluentui/react-components';
import { useEffect, useState, useMemo } from 'react';
import { produce } from "immer"
import { init } from './z3.js'
import { Context, Z3HighLevel, Z3LowLevel } from 'z3-solver';
import { findSolutions } from './calculate.js';

const useStyles = makeStyles({
    title1: typographyStyles.title1
});

let initZ3Promise: Promise<{ z3lib: Z3HighLevel & Z3LowLevel, context: Context }> | undefined = undefined;
function initZ3() {
    // how many initz3 functions do we need? I think we have 5 now
    if (initZ3Promise == undefined) {
        initZ3Promise = (async () => {
            const z3lib = await init();
            const context = z3lib.Context('main');
            return { z3lib, context };
        })();
    }
    return initZ3Promise;
}

export function App() {

    const [mod, setMod] = useState<typeof factorioLabTypes[0] | undefined>(factorioLabTypes[0]);

    const [modData, setModData] = useState<LabData | undefined>(undefined);
    useEffect(() => {
        if (mod === undefined) return;

        document.documentElement.style.setProperty('--iconset', 'url("' + getIconUrl(mod.id) + '")');

        let cancelled = false;

        (async () => {
            setStatus('Loading data for ' + mod.name)
            let data = await getData(mod.id);
            setStatus('');
            if (!cancelled) {
                setModData(data);

                setModules(data.items.filter(x => x.id.startsWith('speed-module') && x.module?.speed).map(item => ({
                    ...item, enabled: true
                })));
            }
        })();

        return () => {
            cancelled = true;
            setModData(undefined);
            setModules([]);
            setRecipe(undefined); // when changing mods, reset recipe.
        };
    }, [mod]);

    const iconStyles = useMemo(() => {
        if (!modData) return '';

        return modData.icons.map(icon => `.icon.${icon.id}::before { background-position: ${icon.position} }`).join('\n')
    }, [modData]);


    const [recipe, setRecipe] = useState<LabRecipe | undefined>(undefined);
    useEffect(() => {
        if (recipe == undefined) return;
        return () => {
            setItem(undefined); // when changing recipe, reset item.
        }
    }, [recipe]);

    const [recipeItems, setRecipeItems] = useState<(LabItem & { net: number })[]>([]);
    const [item, setItem] = useState<(LabItem & { net: number }) | undefined>(undefined);

    const [producers, setProducers] = useState<LabItem[]>([]);
    const [producer, setProducer] = useState<LabItem | undefined>(undefined);

    const [perMinute, setPerMinute] = useState(60);

    const [modules, setModules] = useState<(LabItem & { enabled: boolean })[]>([]);

    const [status, setStatus] = useState('');

    const [solveResult, setSolveResult] = useState<SolveResult>(() => ({
        solving: false
    }));

    async function solve() {
        setSolveResult({
            solving: true
        });

        if (!modData || !recipe || !item || !producer) return;

        setStatus('Loading solver...');
        const { z3lib, context: Z3 } = await initZ3();
        setStatus('Solving...');

        // find the natural amount of machines, and solve from 0 - that.
        let maxMachineCount = perMinute / (60 / recipe.time * item.net * producer.machine!.speed);

        if (maxMachineCount <= 1) {
            // no need.
            setSolveResult({
                solving: false,
                results: [{
                    machines: maxMachineCount,
                    modules: []
                }]
            });
            setStatus('');
            return;
        }
        maxMachineCount = Math.ceil(maxMachineCount);

        const results: SolveResult['results'] = [];

        let modsWithCost = modules.filter(x => x.enabled).map(mod => ({
            speed: mod.module!.speed,
            cost: 10 * Math.pow(3, parseInt(mod.id.match(/(\d+)/)?.[0] ?? '1') - 1),
            mod
        }));

        try {
            for (var i = 1; i <= maxMachineCount; i++) {
                let found = false;
                let smt = await findSolutions(
                    Z3, z3lib,
                    {
                        recipeTime: recipe.time,
                        recipeAmount: item.net,
                        machineSpeed: producer.machine!.speed,
                        modules: modsWithCost,
                        numModules: producer.machine!.modules,
                        wantedPerMinute: perMinute,
                        only: 1,
                        range: { min: i - 1, max: i }
                    }, data => {
                        console.log(data);
                        results.push({
                            machines: data.machines,
                            modules: data.modules.filter(m => m != 0).map(m => modsWithCost[m - 1].mod)
                        });
                        console.log(results);
                        found = true;
                    });


                console.log(smt);

                if (!found) setStatus(`Solver: no solutions for machines ${i - 1} < m <= ${i}`);
            }

            console.log(results);

            setSolveResult({
                solving: false,
                results
            });
            setStatus('');
        } catch (e) {
            setSolveResult({ solving: false, results });
            console.error(e);
            setStatus('Error occurred');
        }
    }

    return <div className="container max-w-3xl mx-auto p-3">
        <style>{iconStyles}</style>
        <FluentProvider theme={webLightTheme}>
            <Title1 block={true} as="h1" className='mb-3'>Factorio Module Calculator</Title1>
            <Subtitle2 block={true} as="h2" className='mb-3'>
                <i>For now, only speed modules. Uses data from <Link href="https://factoriolab.github.io/">FactorioLab</Link>.</i>
            </Subtitle2>

            <div className="grid grid-cols-2 items-baseline gap-3">
                <ModSelector selectedMod={mod} onSelect={id => {
                    setMod(factorioLabTypes.find(x => x.id == id));
                }} />

                {modData && <RecipeSelector recipes={modData.recipes} selectedRecipe={recipe} onSelect={id => {
                    const recipe = modData.recipes.find(x => x.id == id);
                    setRecipe(recipe);
                    if (recipe) {

                        const items = getRecipeItems(modData.items, recipe);
                        setRecipeItems(items);
                        if (items.length > 0) setItem(items[0]);

                        const producers = recipe.producers
                            .map(p => modData.items.find(x => x.id == p)!)
                            .filter(m => m.machine!.modules > 0);

                        setProducers(producers);
                        if (producers.length > 0) setProducer(producers[0]);
                    }
                }} />}

                {recipe && recipeItems.length > 1 && <ItemSelector label="Item" selectedItem={item} items={recipeItems} onSelect={id => {
                    setItem(recipeItems.find(x => x.id == id));
                }} />}

                {recipe && <>
                    <Label className='text-right'>Items Per Minute</Label>
                    <Input value={perMinute.toString()} onChange={(e, data) => {
                        let num = parseInt(data.value);
                        if (!isNaN(num)) setPerMinute(num);
                    }} />
                </>}

                {recipe && <ItemSelector label="Machine" selectedItem={producer} items={producers} onSelect={id => {
                    setProducer(producers.find(x => x.id == id));
                }} />}

                {recipe && <ModuleSelector modules={modules} toggleModule={mod => {
                    let newMods = produce(modules, draft => {
                        let m = draft.find(x => x.id == mod.id)!;
                        m.enabled = !m.enabled;
                    });
                    setModules(newMods);
                }} />}

                {recipe && <div className='col-span-full justify-self-center'>
                    <Button onClick={() => solve()} disabled={solveResult.solving || modules.every(x => x.enabled == false)} appearance="primary">Solve</Button>
                </div>}

                {status && <div className='col-span-full'>
                    <Spinner size="tiny" label={status} />
                </div>}
            </div>

            {solveResult.results && <Table arial-label="Default table">
                <TableHeader>
                    <TableRow>
                        <TableHeaderCell>
                            Machines
                        </TableHeaderCell>
                        <TableHeaderCell>
                            Modules
                        </TableHeaderCell>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {solveResult.results.map(result => (
                        <TableRow key={result.machines}>
                            <TableCell>
                                {result.machines}
                            </TableCell>
                            <TableCell>
                                {result.modules.map(x => x.name).join(', ')}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>}

        </FluentProvider>
    </div>
}

interface ModSelectorProps {
    selectedMod: typeof factorioLabTypes[0] | undefined
    onSelect: (id: string | undefined) => void
}


export function ModSelector({ selectedMod, onSelect }: ModSelectorProps) {
    return <>
        <Label className='text-right'>Factorio Version/Mod</Label>
        <Combobox value={selectedMod?.name} onOptionSelect={(e, data) => {
            onSelect(data.optionValue);
        }}>
            {factorioLabTypes.map((option) => (
                <Option key={option.id} value={option.id}>
                    {option.name}
                </Option>
            ))}
        </Combobox>
    </>
}

interface RecipeSelectorProps {
    recipes: LabRecipe[]
    selectedRecipe: LabRecipe | undefined
    onSelect: (id: string | undefined) => void
}
export function RecipeSelector({ recipes, selectedRecipe, onSelect }: RecipeSelectorProps) {
    return <>
        <Label className='text-right'>Recipe</Label>
        <Combobox placeholder='Choose a recipe' onOptionSelect={(e, data) => {
            onSelect(data.optionValue);
        }}>
            {recipes.map((option) => (
                <Option key={option.id} value={option.id} text={option.name}>
                    <div className='flex gap-1'>
                        <span className={'icon icon19 ' + (option.icon ?? option.id)}></span>{option.name}
                    </div>
                </Option>
            ))}
        </Combobox>
    </>
}

interface ItemSelectorProps {
    selectedItem: LabItem | undefined
    items: LabItem[]
    label: string
    onSelect: (id: string | undefined) => void
}
export function ItemSelector({ label, selectedItem, items, onSelect }: ItemSelectorProps) {
    if (!selectedItem) selectedItem = items[0];

    return <>
        <Label className='text-right'>{label}</Label>
        <Combobox value={selectedItem?.name} onOptionSelect={(e, data) => {
            onSelect(data.optionValue);
        }}>
            {items.map((option) => (
                <Option key={option.id} value={option.id} text={option.name}>
                    <div className='flex gap-1'>
                        <span className={'icon icon19 ' + (option.icon ?? option.id)}></span>{option.name}
                    </div>
                </Option>
            ))}
        </Combobox>
    </>
}

interface ModuleSelectorProps {
    modules: (LabItem & { enabled: boolean })[]
    toggleModule: (module: LabItem) => void
}
export function ModuleSelector(props: ModuleSelectorProps) {
    return <>
        <Label className='text-right'>Enabled Modules</Label>
        <div>
            {props.modules.map(mod => (
                <Checkbox key={mod.id} label={mod.name} checked={mod.enabled} onChange={e => props.toggleModule(mod)} />
            ))}
        </div>
    </>
}


interface SolveResult {
    solving: boolean
    results?: {
        machines: number
        modules: LabItem[]
    }[]
}

// factoriolab/main/src/data/index.ts
// data folder mirrored to factoriolab-adamburgess-data.vercel.app
export const types = [
    { id: '1.1', name: '1.1.x' },
    { id: '1.1e', name: '1.1.x Expensive' },
    { id: '1.0', name: '1.0.0' },
    { id: '017', name: '0.17.79' },
    { id: '016', name: '0.16.51' },
    { id: '248', name: '248k Standalone' },
    { id: '248o', name: '248k Overhaul' },
    { id: '248kr2sxp', name: '248k + Krastorio 2 + SE' },
    { id: 'aai', name: 'AAI Industry' },
    { id: 'ang', name: "Angel's Industries" },
    { id: 'bio', name: 'Bio Industries' },
    { id: 'bob', name: "Bob's Mods" },
    { id: 'bobang', name: "Bob's & Angel's" },
    { id: 'efs', name: 'Electric Furnaces' },
    { id: 'fpp', name: 'Factorio Plus Plus' },
    { id: 'fep', name: 'FactorioExtended Plus' },
    { id: 'ir3', name: 'Industrial Revolution 3' },
    { id: 'kr2', name: 'Krastorio 2' },
    { id: 'kr2aai', name: 'Krastorio 2 + AAI' },
    { id: 'kr2sxp', name: 'Krastorio 2 + SE' },
    { id: 'msc', name: 'More Science' },
    { id: 'nls', name: 'Nullius' },
    { id: 'pys', name: 'Pyanodons' },
    { id: 'pysalf', name: 'Pyanodons + AL' },
    { id: 'qat', name: "Qatavin's More Stuff" },
    { id: 'sea', name: 'Sea Block' },
    { id: 'sxp', name: 'Space Exploration' },
    { id: 'vbz', name: 'Very BZ' },
    { id: 'xan', name: 'Xander Mod' }
];

export interface LabRecipe {
    id: string
    time: number
    name: string
    producers: string[]
    icon?: string

    in: Record<string, number>
    out: Record<string, number>
}
export interface LabItem {
    id: string
    name: string
    icon?: string

    machine?: {
        speed: number
        modules: number
    }

    module?: {
        speed: number
    }

    beacon?: {
        modules: number
        effectivity: number
    }
}
export interface LabIcon {
    id: string
    position: string
}
export interface LabCategory {
    id: string
    name: string
}
export interface LabData {
    recipes: LabRecipe[]
    icons: LabIcon[]
    items: LabItem[]
    categories: LabCategory[]
}

export async function getData(name: string): Promise<LabData> {
    const req = await fetch(`https://factoriolab-adamburgess-data.vercel.app/${name}/data.json`);
    const data = await req.json();

    return data as LabData;
}

export function getIconUrl(name: string) {
    return `https://factoriolab-adamburgess-data.vercel.app/${name}/icons.webp`
}

export function getRecipeItems(items: LabItem[], recipe: LabRecipe) {
    // find only the outputs of the recipe
    const totals: Record<string, LabItem & { net: number }> = {};
    for (let ins in recipe.in) {
        let item = items.find(x => x.id == ins)!;
        totals[ins] = { ...item, net: -recipe.in[ins] };
    }
    for (let outs in recipe.out) {
        let item = items.find(x => x.id == outs)!;
        if (outs in totals) totals[outs].net += recipe.out[outs];
        else totals[outs] = { ...item, net: recipe.out[outs] };
    }

    return Object.values(totals).filter(x => x.net > 0);
}

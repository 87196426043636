import { createRoot } from 'react-dom/client';
import { Signal, signal } from '@preact/signals-react';

import { init } from './z3.js'
import { LabData, getData, getIconUrl, types as factorioLabTypes } from './factoriolab-data.js';
import { findSolutions } from './calculate.js';
import { useEffect, useState } from 'react';

import './index.css'
import { App } from './app.js';

const data: Signal<LabData | undefined> = signal(undefined);

(async () => {
    const z3lib = await init();
    console.log('initialised');


    // lets try and solve something.
    const Z3 = z3lib.Context('main');

    const icon = await getIconUrl('sxp');


    const data = await getData('sxp');

    const recipe = data.recipes.find(x => x.id == 'se-heat-shielding')!;


    // const ranges = [
    //     { min: 0, max: 1, },
    //     { min: 1, max: 2 },
    //     { min: 2, max: 4 },
    //     { min: 4, max: 6 },
    //     { min: 6, max: 8 }
    // ];

    // for (const range of ranges) {
    //     let found = false;
    //     await findSolutions(
    //         Z3, z3lib,
    //         {
    //             recipeTime: recipe.time,
    //             recipeAmount: recipe.out['se-heat-shielding'],
    //             machineSpeed: 1.25,
    //             modules: [{
    //                 speed: 0.2,
    //                 cost: 10
    //             }, {
    //                 speed: 0.3,
    //                 cost: 30
    //             }, {
    //                 speed: 0.4,
    //                 cost: 90
    //             }],
    //             numModules: 4,
    //             wantedPerMinute: 120,
    //             only: 1,
    //             range: range
    //         }, data => {
    //             console.log(range, data);
    //             found = true;
    //         });
    //     if (!found) console.log(range, 'no solution');
    // }


})();


/*


The idea.

1. Select an item to make.
2. Select how many items/min you want to make.
3. Optionally, enable/disable use of certain speed modules. LATER for now just speed 1/2/3
4. Optionally, enable/disable use of certain machines. LATER. for now just use assembling machine 3
5. Optionally, select how many beacons you want ??? later... need to think about how to do it in z3.
6. Select strategy --
    "show solutions closest to 1 machine, and then an even number "
    "show solutions closest to an even number of machines"
6. press GO, and it should give you a list of solutions.. nice!





console.log('done!');



*/

interface IconProps {
    id: string
}

function Icon(props: IconProps) {
    // lookup this icon
}

function SelectedMod({ mod }: { mod: string }) {

    const [modData, setModData] = useState<LabData | undefined>(undefined);

    useEffect(() => {
        let cancelled = false;

        (async () => {
            let data = await getData(mod);
            if (!cancelled) setModData(data);
        })();

        return () => {
            cancelled = true;
            setModData(undefined);
        };
    }, [mod]);

    if (modData == undefined) return <div>loading data for mod</div>

    return <div>
        <div>mod data loaded {modData.recipes.length} recipes</div>
        <RecipeChooser data={modData} />

    </div>
}

function RecipeChooser({ data }: { data: LabData }) {
    const [recipe, setRecipe] = useState<string | undefined>(undefined);

    return <div>
        <select onChange={e => {
            setRecipe(e.currentTarget.value)
        }}>
            {data.recipes.map(x => <option value={x.id}>{x.name}</option>)}
        </select>
    </div>;

}

const root = createRoot(document.getElementById('app') as HTMLElement);
root.render(<App />);

